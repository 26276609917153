import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerAnalytics from '../images/layerAnalytics.svg';
import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerRealtime from '../images/layerRealtime.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ModernBuyersJourney from '../images/modernBuyersJourney.png';
import LibertyRing from '../animations/libertyRing.json';

import Default from '../../components/Layouts/Default';

var lottie;

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.animationIsAttached = false;
  }

  componentDidMount() {
    lottie = require('lottie-web');
    this.attachAnimation();
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing
      };

      lottie.loadAnimation(animationProperties);
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Platform</title>
          <meta
            name="description"
            content="Accessible, Useful, and Safe. These are the benefits of adopting an all-in-one voice platform."
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                What Is Revenue Agility?
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Outmaneuver the competition by better understanding and adapting to your cutomer's needs
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                The World Has Changed
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>More Competition:</b></u> In the past 5 years, competition in each SaaS category has gone up 3x (src: OpenView).  Incumbents are entering  new segments and disruptors are carving out new niches faster than ever before. 
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Higher Expectations:</b></u> At the same time, the average NPS has gone down by 10 points.  Consumers expect an Amazon-like experience everywhere.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Team Selling:</b></u> With the shift to Recurring Revenue, sellers have to align more people than ever before (executive sponsors, solutions engineers, implementation, product and customer success) before they can unlock the true revenue potential of a customer.
              </Large>
            </Box>
            <br/><br/><br/>
           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                CRM Wasn't Designed For The Modern Buyer's Journey 
            </PageTitle2>
            <br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
              <CenteredImg src={ModernBuyersJourney} alt="Modern Buyers Journey"/>
            </Box>
            <br/><br/><br/>
            <PageTitle2 as="h1" color={colors.trulyBlue} bold my="1.5rem">
                With Revenue Agility, You No Longer Need To Sacrifice Process For Data
            </PageTitle2>
            <Large as="p" color={colors.trulyDark} mb="1.5rem">
              Today, most of our sales interactions are virtual, giving us unparalleled potential to 'see' into our customer conversations with data (over 1 Trillion words are spoken on Sales/Support calls vs. 2.7B Twitter words!)
              <br/><br/>
              Revenue Agility allows you to tap into this data, quantify it (with traditional and modern AI techniques) and create powerful reporting/automations to accelerate your business.
            </Large>
            <br/><br/><br/>
          </Section>

        </WrapperSolid>

        <Values>
          <Section flexDirection="column">
            <Title2 color={colors.accentFog} mt="32pt">
              6 Pillars Of Revenue Agility
            </Title2>
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerConnector} alt="Connector"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Data Confidence
              </PageTitle3>
              <Large center color={colors.white}>
                Your team can't make high quality decisions quickly if they think they're missing information.  The stakes of guessing are just too high when you're trying to close deals or performance managing reps, so most people will delay action until they are confident in their decision.
                <br/><br/>
                You need to make sure that your team has a high degree of confidence in their data, so they can minimize time to action and revenue.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Lean Stack
              </PageTitle3>
              <Large center color={colors.white}>
                Technology is moving faster than ever before and giving agile companies an unfair advantage in how they engage their customers at scale.
                <br/><br/>
                You need to be able to change parts of your stack at any time to take advantage of the latest technology, without difficult change management and complex tradeoffs.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pl={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerTelephony} alt="Telephony"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerGlobal} alt="Global"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Real-Time
              </PageTitle3>
              <Large center color={colors.white}>
                Markets are moving faster than ever before.  Every week, competitors are expanding product offerings while disruptors are carving out niches.  As the economy adjusts to a post-COVID world, customers' budgets and business strategies are changing daily. You can't afford to wait for weekly, monthly and quarterly reporting cycles to adapt to these changes.
                <br/><br/>
                  You need your data to be real-time to thrive in this rapidly changing world.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                One Shared Lens
              </PageTitle3>
              <Large center color={colors.white}>
                Everyone on the team views the world through their own lens, with unique context and experience.  The inability to reconcile these differences leads to mistakes, frustration and time-consuming meetings.
                <br/><br/>
                To bridge this gap, you need to get every person on every team seeing/hearing the customer voice themselves.  The easier it is to find, consume and collaborate around this information, the better your outcomes will be.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerWorkflow} alt="Workflow"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerRealtime} alt="Realtime"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Put The Customer At The Center
              </PageTitle3>
              <Large center color={colors.white}>
                Customers have more choice and higher expectations than ever before.
                <br/><br/>
                In a recurring revenue world, you have to earn the business every day.  Customer obsession is the only path to long term success.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']} pb="64pt">
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Empower The Front Lines
              </PageTitle3>
              <Large center color={colors.white}>
                Nobody is better positioned to understand and satisfy the customer faster than your front line reps.  
                <br/><br/>
                The best SaaS companies excel at delivering exceptional clarity (information + process) to their end users, let them exercise their judgment and deliver data-driven coaching to improve outcomes over the long run.

              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerAnalytics} alt="Analytics"/>
            </Box>
          </Section>
        </Values>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
